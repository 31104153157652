import { Card, Row } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";

const Development = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [activeButton, setActiveButton] = useState<"next" | "previous" | null>(
    null
  );

  const totalDivs = 9;
  const visibleCount = 1;

  const divs = [
    {
      id: 1,
      content: "Twilight by Binghatti at Al Jaddaf",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Binghatti-Ghost-at-Al-Jaddaf-570x370.jpg",
      para1: "Dubai Hills Estate",
    },
    {
      id: 2,
      content: "Golf Hillside at Dubai Hills Estate",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
      para1: "Al Jaddaf,Dubai",
    },
    {
      id: 3,
      content: "Oasiz by Danube at Dubai Silicon Oasis",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Oasiz-by-Danube-at-Dubai-Silicon-Oasis-6-570x370.png",
      para1: "Dubai Silicon Oasis",
    },
    {
      id: 4,
      content: "Lavita at The Oasis",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Lavita-at-The-Oasis-5-570x370.jpg",
      para1: "The Oasis",
    },
    {
      id: 5,
      content: "The Rings by PMR",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/The-Rings-by-PMR-19-570x370.jpg",
      para1: "Dubai Water Canel,Jumeria",
    },
    {
      id: 6,
      content: "The Beach Collection Villas by Nakheel",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Damac-Sun-City-at-Dubailand-570x370.jpg",
      para1: "Dubai Water Canel,Jumeria",
    },
    {
      id: 7,
      content: "Hillcrest at Town Square Dubai",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Hillcrest-at-Town-Square-Dubai-11.jpg",
      para1: "Dubailand,Dubai",
    },
    {
      id: 8,
      content: "Golf Hillside at Dubai Hills Estate",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Golf-Hillside-at-Dubai-Hills-Estate-570x370.jpg",
      para1: "DubaiHillEstate,Dubai",
    },
    {
      id: 10,
      content: "Cove Edition by Imtiaz",
      img: "https://houseandhedges.ae/wp-content/uploads/2024/09/Cove-Edition-by-Imtiaz-14-570x370.jpg",
      para1: "Dubailand",
    },
  ];

  // Handle the "greater than" button click
  const handleNext = () => {
    setActiveButton("next");
    if (startIndex + visibleCount < totalDivs) {
      setStartIndex(startIndex + 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  // Handle the "less than" button click
  const handlePrevious = () => {
    setActiveButton("previous");
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
    setTimeout(() => setActiveButton(null), 300);
  };

  const naviagte = useNavigate();
  const handleNav = (id: any) => {
    naviagte(`/offplan/${id}`);
  };

  return (
    <>
      <section className="pt-3 section-r">
        <Container>
          <div className="gaps">
            <div className="d-flex justify-content-between">
              <div>
                <h2 className="m-txt">New developments</h2>
                <p className="txt-1-m">
                  Take a look at new <a href="https://www.dimoora.site/offplan" style={{color:"white"}}>off-plan developments</a> in and around Dubai
                </p>
              </div>
            </div>

            <Row className="pt-3 row-develops">
              <Col sm={12} md={12} lg={7}>
                <div className="gaps-img">
                  {/* Less Than Button */}
                  <div className="less-div">
                    <button
                      className={`lessthan ${
                        activeButton === "previous" ? "active" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={startIndex === 0}
                    >
                      <i className="fi fi-rr-angle-small-left d-icons"></i>
                    </button>
                  </div>

                  {/* Display only the visible divs */}
                  <div className="d-flex product-div gap align-items-center">
                    {divs
                      .slice(startIndex, startIndex + visibleCount)
                      .map((div) => (
                        <div key={div.id} className="custom-div">
                          <div className="img-div-develop">
                            <img
                              src={div.img}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* Greater Than Button */}
                  <div className="greater-div">
                    <button
                      className={`greaterthan ${
                        activeButton === "next" ? "active" : ""
                      }`}
                      onClick={handleNext}
                      disabled={startIndex + visibleCount >= totalDivs}
                    >
                      <i className="fi fi-rr-angle-small-right d-icons"></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} lg={5} xs={12}>
                {/* Display only the visible divs */}
                <div className="product-div-1 ">
                  {divs
                    .slice(startIndex, startIndex + visibleCount)
                    .map((div) => (
                      <div key={div.id} className="custom-div d-flex">
                        <div className="content-div text-right ms-lg-3">
                          <h3 className="txt-7">{div.content}</h3>
                          <p className="txt-8">{div.para1}</p>
                          <div>
                            <div className="icons-m">
                              <div>
                                <i className="fi fi-rs-heart icon-offs"></i>
                              </div>
                              <div>
                                <i className="fi fi-rs-multiple icon-offs"></i>
                              </div>
                              <div>
                                <i className="fi fi-rs-share icon-offs"></i>
                              </div>
                            </div>
                            <div className="btn-div-m">
                              <Button
                                className="btn-ani-1"
                                onClick={() => handleNav(div.id)}
                              >
                                <span> Off Plan</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Development;
