import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="footer pt-4">
        <Container>
          <div>
            <Row>
              <Col lg={4} sm={12} md={6}>
                <div>
                  <div className="pt-4">
                    <img
                      src="/assets/bg.png"
                      alt="logo"
                      className="img-fluid logo"
                    />
                  </div>
                  <p className="txt-9-1 pt-4">Follow us on social media</p>

                  <div className="d-flex gap-3 ">
                    <div>
                      <a href="https://www.facebook.com/profile.php?id=61565695876899">
                        <i className="fi fi-brands-facebook  icon-off"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://x.com/dimoora68987">
                        <i className="fi fi-brands-twitter-alt icon-off"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.youtube.com/@Dimoora">
                        <i className="fi fi-brands-youtube icon-off"></i>
                      </a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/dimoora_property_sales/">
                        <i className="fi fi-brands-instagram icon-off"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={12} md={6}>
                <div className="pt-4">
                  <h4 className="txt-9-1">Contact</h4>
                  <div className="d-flex gap-2">
                    <p>
                      <i className="fi fi-rs-envelope footer-icon"></i>
                    </p>
                    <p className="txt-9">info@dimoora.site</p>
                  </div>
                  <div className="d-flex gap-2">
                    <p>
                      <i className="fi fi-rs-phone-call footer-icon"></i>
                    </p>
                    <p className="txt-9"> +971 50 125 7167</p>
                  </div>
                  <div>
                    <div className="flag-div">
                      <p className="flag">
                        <img
                          src="/assets/india.png"
                          alt="img"
                          className="img-fluid"
                        />
                      </p>
                      <p className="txt-9">Chennai,India</p>
                    </div>

                    <div className="flag-div">
                      <p className="flag">
                        <img
                          src="/assets/Dubai.png"
                          alt="img"
                          className="img-fluid"
                        />
                      </p>
                      <p className="txt-9">Dubai,UAE</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} sm={12} md={6}>
                <div className="pt-4">
                  <h4 className="txt-9-1">Popular Search</h4>
                  <p className="txt-9">Jumeirah Village Circle (JVC)</p>
                  <p className="txt-9">Dubai Hills Estate</p>
                  <p className="txt-9">Palm Jumeirah</p>
                  <p className="txt-9">Arjan</p>
                  <p className="txt-9">DAMAC Hills</p>
                  <p className="txt-9">Al Barsha</p>
                </div>
              </Col>
              <Col lg={2} sm={12} md={6}>
                <div className="pt-4">
                  <h5 className="txt-9-1">Quick Links</h5>
                  <Link to="/termsofuse">
                    <p className="txt-9">Terms of Use</p>
                  </Link>
                  <Link to="/privacypolicy">
                    <p className="txt-9">Privacy Policy</p>
                  </Link>
                  <Link to="/careers">
                    <p className="txt-9">Careers</p>
                  </Link>
                  <Link to="/contact">
                    <p className="txt-9">Contact</p>
                  </Link>
                </div>
              </Col>
            </Row>

            <div className="footer-f">
              <p className="f-footer">© Dimoora All rights reserved</p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
