import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Dropdown, MenuProps } from "antd";
import { Space } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

const NavComponent = () => {
  // State to track scroll position for desktop and mobile
  const [isScrolled, setIsScrolled] = useState(false);
  const [ismScrolled, setmIsScrolled] = useState(false);
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Detect scroll position and update state for desktop
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && location.pathname === "/") {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  // Detect scroll position for mobile
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350 && location.pathname === "/") {
        setmIsScrolled(true);
      } else {
        setmIsScrolled(false);
      }
    };

    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const [activeKey, setActiveKey] = useState<string>("0");

  const handleClick = (key: string) => {
    setActiveKey(key);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Link
          className={`txt-6 link-hover ${activeKey === "0" ? "active" : ""}`}
          to="/buy-properties-in-dubai-off-plan"
          onClick={() => handleClick("0")}
        >
          Off plan
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link
          className={`txt-6 link-hover ${activeKey === "1" ? "active" : ""}`}
          to="/apartments-for-sale-in-dubai-on-plan"
          onClick={() => handleClick("1")}
        >
          On plan
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          className={`txt-6 link-hover ${activeKey === "2" ? "active" : ""}`}
          to="/currentplan"
          onClick={() => handleClick("2")}
        >
          Ready to move
        </Link>
      ),
      key: "2",
    },
  ];

  const service: MenuProps["items"] = [
    {
      label: (
        <a
          className="txt-6"
          href="#buy"
          onClick={() => navigate("/ourservice")}
        >
          Buy
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          className="txt-6"
          href="#sell"
          onClick={() => navigate("/ourservice")}
        >
          Sell
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a
          className="txt-6"
          href="#rent"
          onClick={() => navigate("/ourservice")}
        >
          Rent
        </a>
      ),
      key: "2",
    },
  ];

  // Apply different styles based on the current page
  
  const isHomepage = location.pathname === "/";
  const navbarStyle = {
    backgroundColor: isHomepage
      ? isScrolled
        ? "rgba(0, 0, 0, 0.9)"
        : "transparent"
      : "rgba(0, 0, 0, 0.9)",
    backgroundImage: isHomepage
      ? "url('/path/to/homepage-image.jpg')" 
      : "none",
    backgroundSize: "cover",
    transition: "background-color 0.3s ease",
  };

  const mobileNavbarStyle = {
    backgroundColor: isHomepage
      ? ismScrolled
        ? "rgba(0, 0, 0, 0.9)"
        : "transparent" 
      : "rgba(0, 0, 0, 0.9)", 
    backgroundImage:
      isHomepage && !ismScrolled
        ? "url('/path/to/homepage-image.jpg')"
        : "none",
    backgroundSize: "cover",
    transition: "background-color 0.3s ease, background-image 0.3s ease", 
  };

  // Handle toggling dropdown menus for mobile view
  const toggleProjectsMenu = () => {
    setIsProjectsOpen(!isProjectsOpen);
  };

  const toggleServicesMenu = () => {
    setIsServiceOpen(!isServiceOpen);
  };

  return (
    <>
      {/* Mobile Navbar */}
      <section className="d-sm-block d-lg-none bg-nav">
        <Navbar
          collapseOnSelect
          expand="lg"
          className={`lg-hide m-header ${ismScrolled ? "scrolled" : ""}`}
          style={mobileNavbarStyle}
        >
          <Container>
            <Navbar.Brand href="/">
              <img className="img-fluid logo" src="/assets/bg.png" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="nav-icon"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="servicebd-color">
                <Link to="/" className="head-nav">
                  Home
                </Link>
                <div
                  onClick={toggleProjectsMenu}
                  className="head-nav d-flex align-items-center"
                >
                  Projects
                  <i
                    className={`ms-2 mt-2 fi fi-rs-angle-small-${
                      isProjectsOpen ? "up" : "down"
                    }`}
                  ></i>
                </div>
                {isProjectsOpen && (
                  <div className="dropdown-menu-mobile">
                    <Link to="/offplan" className="dropdown-item head-nav">
                      Off Plan
                    </Link>
                    <Link to="/apartments-for-sale-in-dubai-on-plan" className="dropdown-item head-nav">
                      On Plan
                    </Link>
                    <Link to="/currentplan" className="dropdown-item head-nav">
                      Ready to Move
                    </Link>
                  </div>
                )}
                <div
                  onClick={toggleServicesMenu}
                  className="head-nav d-flex align-items-center"
                >
                  Our Service
                  <i
                    className={`ms-2 mt-2 fi fi-rs-angle-small-${
                      isServiceOpen ? "up" : "down"
                    }`}
                  ></i>
                </div>
                {isServiceOpen && (
                  <div className="dropdown-menu-mobile-1">
                    <a
                      className="dropdown-item head-nav"
                      href="#buy"
                      onClick={() => navigate("/ourservice")}
                    >
                      Buy
                    </a>

                    <a
                      className="dropdown-item head-nav"
                      href="#sell"
                      onClick={() => navigate("/ourservice")}
                    >
                      Sell
                    </a>

                    <a
                      className="dropdown-item head-nav"
                      href="#rent"
                      onClick={() => navigate("/ourservice")}
                    >
                      Rent
                    </a>
                  </div>
                )}
                <Link to="/blogs" className="head-nav">
                  Blogs
                </Link>
                <Link to="/contact" className="head-nav">
                  Contact
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </section>

      {/* Desktop Navbar */}
      <section
        className={`navbar-contain ${isScrolled ? "scrolled-navbar" : ""}`}
        style={navbarStyle}
      >
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <a href="/">
              <img src="/assets/bg.png" alt="logo" className="img-fluid logo" />
            </a>

            <div className="d-flex justify-content-evenly align-items-center gap-5">
              <a href="/" className="txt-8">
                Home
              </a>
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space className="txt-8">
                    Projects
                    <div className="mt-2">
                      <i className="fi fi-rs-angle-small-down"></i>
                    </div>
                  </Space>
                </a>
              </Dropdown>
              <Dropdown menu={{ items: service }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space className="txt-8">
                    Our Service
                    <div className="mt-2">
                      <i className="fi fi-rs-angle-small-down"></i>
                    </div>
                  </Space>
                </a>
              </Dropdown>
              <a href="/blogs" className="txt-8">
                Blogs
              </a>
              <a href="/contact" className="txt-8">
                Contact
              </a>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default NavComponent;
